import { CategorizedCompaniesResponse } from '../employers';
import { ProductType } from '../enums';
import { ExternalFeature } from '../types';
import type { BridgeTokenResponse } from '../types.zod';

export type OriginQueryParams = Record<string, unknown>;

export type BridgeWidgetInitConfigMessageData = {
  bridgeToken: string;
  clientName?: string;
  origin: string;
  queryParams?: OriginQueryParams;
  product?: ProductType;
  trackingInfo: string;
  additionalInfo?: {
    hasExternalLinkProcessor?: boolean;
    isMobileApp?: boolean;
    userTraits?: {
      autoOpenWidget?: boolean;
    };
    supportedExternalFeatures?: ExternalFeature[];
    originalReferrer?: string;
  };
};

export type BridgeWidgetInitConfigMessage = {
  source: 'bridge';
  type: BridgeWidgetMessageType.Init;
  data: BridgeWidgetInitConfigMessageData;
};

export type BridgeWidgetCFPreviewMessage = {
  source: 'bridge';
  data: {
    // CustomFormsSchemeResponse
    scheme: any;
  };
};

export type BridgeWidgetCFPreviewInitMessage = BridgeWidgetCFPreviewMessage & {
  type: BridgeWidgetMessageType.CustomFormPreviewInit;
};

export type BridgeWidgetCFPreviewSubmitResponseMessage = BridgeWidgetCFPreviewMessage & {
  type: BridgeWidgetMessageType.CustomFormPreviewSubmitResponse;
};

export type CloseData = { employer: { name: string } };
export enum BridgeWidgetMessageType {
  Init = 'init',
  SetBridgeTokenResponse = 'set_bridge_token_response',
  SetCategorizedCompaniesResponse = 'set_categorized_companies_response',
  GoBack = 'go_back',
  ExternalLoginSuccess = 'external_login_success',
  ExternalLoginCancel = 'external_login_cancel',
  CustomFormPreviewInit = 'custom_form_preview_init',
  CustomFormPreviewSubmitResponse = 'custom_form_preview_submit_response',
}

export type ExternalLoginSuccessData = { cookies: any; dashboard_url: string; tags?: string[] };

export type BridgeWidgetMessage =
  | {
      source: 'bridge';
      type: BridgeWidgetMessageType.GoBack | BridgeWidgetMessageType.ExternalLoginCancel;
    }
  | BridgeWidgetInitConfigMessage
  | {
      source: 'bridge';
      type: BridgeWidgetMessageType.SetBridgeTokenResponse;
      data: BridgeTokenResponse;
    }
  | {
      source: 'bridge';
      type: BridgeWidgetMessageType.SetCategorizedCompaniesResponse;
      data: CategorizedCompaniesResponse;
    }
  | { source: 'bridge'; type: BridgeWidgetMessageType.ExternalLoginSuccess; data: ExternalLoginSuccessData }
  | BridgeWidgetCFPreviewInitMessage
  | BridgeWidgetCFPreviewSubmitResponseMessage;
