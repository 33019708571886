export enum ProductType {
  ADMIN = 'admin',
  DEPOSIT_SWITCH = 'deposit_switch',
  PLL = 'pll',
  EMPLOYMENT = 'employment',
  INCOME = 'income',
  INSURANCE = 'insurance',
  TRANSACTIONS = 'transactions',
  ASSETS = 'assets',
}

export enum KeyEnv {
  DEV = 'dev',
  PROD = 'prod',
  SANDBOX = 'sandbox',
}

export enum MappingType {
  Google = 'google',
  Truv = 'truv',
  Manual = 'manual',
  Domain = 'domain',
}

export enum DepositType {
  AMOUNT = 'amount',
  ENTIRE = 'entire',
  PERCENT = 'percent',
}

export enum DataSource {
  PAYROLL = 'payroll',
  DOCS = 'docs',
  FINANCIAL_ACCOUNTS = 'financial_accounts',
  INSURANCE = 'insurance',
  TAX = 'tax',
  SCORING_ATTRIBUTES = 'scoring_attributes',
}

export enum DataSourceFlow {
  FALLBACK = 'fallback',
  WATERFALL = 'waterfall',
}

export enum DocsUploadType {
  Paystub = 'PAYSTUB',
  W2 = 'W2',
  F1099 = 'F1099',
  F1040 = 'F1040',
  InsuranceHome = 'INSURANCE_HOME_POLICY',
  InsuranceAuto = 'INSURANCE_AUTO_POLICY',
}
