import { MappingType } from './enums';
import type { LogoSlug } from './types.zod';

export type CompaniesCategory = {
  id: string;
  name: string;
  mappings: MappingEmployer[];
  show_search_item?: boolean | null;
  is_visible?: boolean;
};

export type CategorizedCompaniesResponse = CompaniesCategory[];

export type Employer = MappingEmployer | FakeEmployer | GoogleEmployer | DomainEmployer;

export type MappingEmployer = {
  type: MappingType.Truv;
  company_mapping_id: string;
  logo_url?: string | null;
  logo_slug?: LogoSlug | null;
  name: string;
  is_connected: boolean;
  domain: string;
  is_corporate: boolean;
};

export type DomainEmployer = {
  type: MappingType.Domain;
  logo_url?: string | null;
  name: string;
  domain: string;
  is_connected: boolean;
};

export type GoogleEmployer = {
  type: MappingType.Google;
  logo_url?: string;
  name: string;
  address: string;
  value: string;
  logo_slug?: LogoSlug;
};

export type FakeEmployer = {
  type: MappingType.Manual;
  name: string;
  logo_url?: string;
  logo_slug?: LogoSlug;
};
